import React, { useState } from "react";
import Nav from "./Navbar";
import Footer from "./Footer";
import $ from "jquery";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { makeStyles } from "@material-ui/core/styles";
import { googleAnalyticsActions } from "../utils/google-analytics-init";
import { useEffect } from "react";

$(document).on("click", ".dropdown li a", function (e) {
  e.preventDefault();
});
$(document).on("click", ".wrapper-demo", function () {
  $(this).children(".wrapper-dropdown-3").toggleClass("active");
});
$(document).on("click", ".dropdown li", function () {
  $(this).parent().prev("span").text($(this).children("a").text());
});

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [idropDown, setidropDown] = useState(false);
  const [pdropDown, setpdropDown] = useState(false);
  const [industries, setIndustries] = useState("");
  const [message, setMessage] = useState("");
  // const classes = useStyles();
  const [statuspop, setstatuspop] = useState({
    open: false,
    text: "",
    color: "",
  });


  const [personName, setPersonName] = React.useState([]);
  const [dropdownIndustries, setdropdownIndustries] = useState(false)
  const [dropdownProduct, setdropdownProduct] = useState(false)

  $(document).on("click", ".alpha li", function () {
    setCompany($(this).children("a").text());
  });
  $(document).on("click", ".omega li", function () {
    setIndustries($(this).children("a").text());
  });

  // $(document).on("click", "body", function () {
  
  //   setdropdownProduct(false);
  //   setdropdownIndustries(false);
   
  
  // });

  $(document).on("click", "#dropdown1", function () {
   
     setdropdownIndustries(true);
     setdropdownProduct(false);

  });
  $(document).on("click", "#dropdown2", function () {
    
    setdropdownProduct(true);
    setdropdownIndustries(false);
   

 });


  const data = {
    name,
    email,
    phone,
    company,
    industries,
    message,
  };
  const submit = (e) => {
    e.preventDefault();
    console.log(data);
    if (name || email || phone || company || industries || message == false) {
      setstatuspop({
        color: "error",
        open: true,
        text: "Form submission failed",
      });
    } else {
      setstatuspop({
        color: "success",
        open: true,
        text: "Form submitted successfully",
      });
    }

    $.ajax({
      url: "https://melioristic-allowan.000webhostapp.com//contact/contact-form.php",
      method: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify(data),
      success: function (data) {
        console.log(data);
      },
    });
  };
  useEffect(()=>{
    googleAnalyticsActions.initGoogleAnalytics();
},[]);
  return (
    <div style={{display: 'flex',flexDirection: 'column',height: '100%'}}>
      <Nav></Nav>
      <div className="faq bigups1 row container">
        <div style={{ width: "100%" }}>
          <br />
          {statuspop && (
            <div>
              <Snackbar
                open={statuspop.open}
                TransitionComponent="SlideTransition"
                autoHideDuration={6000}
                onClose={() => {
                  setstatuspop({
                    ...statuspop,
                    open: false,
                  });
                }}
              >
                <Alert
                  onClose={() => {
                    setstatuspop({
                      ...statuspop,
                      open: false,
                    });
                  }}
                  severity={statuspop.color}
                  sx={{ width: "100%" }}
                >
                  {statuspop.text}
                </Alert>
              </Snackbar>
            </div>
          )}

          <br />

          <br />

          <h1 style={{ fontWeight: 900, textAlign: "center" }}>
            Get in touch with us
          </h1>
          <br />
          <br />
        </div>
        <div className="row journey" style={{ backgroundColor: "white"}}>
          <div className="col-md-5">
            <span>
              Give us a call or drop an email anytime, we endeavour to answer
              all enquiries within 24 hours on business days.
            </span>
            <div className="card-details">
              <b className="journey2">
                <i className="icon-envelope"></i>
                <a href="mailto:contact@stegofy.com">contact@stegofy.com</a>
              </b>
              <b className="journey2">
                <i className="icon-phone"></i>
                <a href="tel:+91 9959584060">+91 9959584060</a>
              </b>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6 journey">
            <form>
              <div className="form-body">
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                />
              </div>
              <div className="form-body2">
                <input
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <input
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone"
                />
              </div>
              <div className="form-body2">
                <input
                  type="text"
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Company"
                />
                <div className="wrapper-demo" id="dropdown1">
					<div className="wrapper-dropdown-3">
						<span>Industries</span>
            {dropdownIndustries && 	<ul className="dropdown alpha disable">
                            <li><a href="#">FMCG</a></li>
                            <li><a href="#">Transport</a></li>
                            <li><a href="#">Electronics</a></li>
                            <li><a href="#">Cosmetic</a></li>
                            <li><a href="#">Auto parts</a></li>
                            <li><a href="#">Fashion Clothing</a></li>
							<li><a href="#">Spirits & Wine</a></li>
							<li><a href="#">Pharmaceutical</a></li>
						</ul> }
					
					</div>
				​</div>
              
              </div>

              <div className="form-body" id="dropdown2">
                            <div className="wrapper-demo wrapper-demo2">
					            <div className="wrapper-dropdown-3" >
						            <span>Product Items Per Year</span>
                        {dropdownProduct &&   <ul class="dropdown omega">
                                    <li><a href="#">0 - 100</a></li>
                                    <li><a href="#">1000 - 5000</a></li>
                                    <li><a href="#">1000 - 10K</a></li>
                                    <li><a href="#">10K - 100K</a></li>
                                    <li><a href="#">More than 100K</a></li>
						            </ul>
                        }
						          
					            </div>
				        ​   </div>
                        </div>


              <div className="form-body">
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  type="textarea"
                  placeholder="Message"
                ></textarea>
              </div>
              <button href="#" onClick={submit} className="blue-face">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Contact;


import phone1 from "../journey/iPhone 8 - 1.png";
import phone2 from "../journey/iPhone 8 - 2.png";
import phone3 from "../journey/iPhone 8 - 4.png";
import phone4 from "../journey/iPhone 8 - 5.png";
import phone5 from "../journey/iPhone 8 - 6.png";

export const SliderData = [
    {
      image:
        phone1
    },
    {
      image:
        phone2
    },
    {
      image:
        phone3
    },
    {
      image:
        phone4
    },
    {
      image:
        phone5
    }
  ];
import React from "react";
import headerpics from '../svg/undraw_Alert_re_j2op 1.svg';

const Header = ()=> {
    return(
        <header className="row">
            <div className="col-md-1"></div>
            <div className="col-md-5 mmh" style={{display:'grid',alignContent: 'center',height: '100%',marginTop: '65px'}}>
                <div>
                    <h1 style={{fontSize:'48px'}}>Bring <span className="theme-color">Authenticity </span>To Your Products </h1>
                    <span style={{color:'#74899d',display:'block',maxWidth:370}}>Helping customers buy genuine products smartly with a great customer experience</span>
                    <br/><br/>
                    <a href="/Contact" className="blue-face" >Request Demo</a>
                </div>
            </div>
            <div className="col-md-6 " style={{marginTop:'45px'}} >
                <div className="header-image">
                <img src={headerpics} />
                </div>
                
            </div>
        </header>
    )
    
}

export default Header;
import React from "react";
import Nav from './Navbar';
import Footer from './Footer';
import image1 from '../journey/Rectangle 224.svg';
import image3 from '../journey/Rectangle 230.svg'
import { googleAnalyticsActions } from "../utils/google-analytics-init";
import { useEffect } from "react";

const Journey= ()=> {
    useEffect(()=>{
        googleAnalyticsActions.initGoogleAnalytics();
    },[]);
    return(
        <div>
            <Nav></Nav>
            <div className="faq row container">
            <div style={{width:'100%'}}>
            <br/>
                <br/>
                <br/>
                <h1 style={{fontWeight:900,textAlign:'center'}}>Our Journey</h1>
                <br/>
                <br/>
            </div>
            <div className="row journey">
                <div className="col-md-6">
                    <img src={image1} style={{width:'90%',display:'flex',margin:'auto'}}/>
                </div>
                <div className="col-md-6" style={{display:'grid',alignContent: 'center'}}>
                    <h2>How It Started ? </h2>
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                          when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                          It has survived not only five centuries, but also the leap into electronic typesetting, 
                          remaining essentially unchanged.
                    </span>
                </div>
            </div>

            <div className="row journey">
                
                <div className="col-md-6" style={{display:'grid',alignContent: 'center'}}>
                    <h2>What we Envision </h2>
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                          when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                          It has survived not only five centuries, but also the leap into electronic typesetting, 
                          remaining essentially unchanged.
                    </span>
                </div>
                <div className="col-md-6">
                <img src={image1} style={{width:'90%',display:'flex',margin:'auto'}}/>
                </div>
            </div>

            <div className="row journey">
                <div className="col-md-6">
                <img src={image1} style={{width:'90%',display:'flex',margin:'auto'}}/>
                </div>
                <div className="col-md-6" style={{display:'grid',alignContent: 'center'}}>
                    <h2>Shot Term Plan </h2>
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                          when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                          It has survived not only five centuries, but also the leap into electronic typesetting, 
                          remaining essentially unchanged.
                    </span>
                </div>
            </div>
            <div className="row" style={{position:'relative',marginBottom:30}}>
                    <img src={image3} style={{width:'100%'}}/>
                <span className="bigups">”When everyone moves forward together, success takes care of itself“</span>
            </div>
            </div>
            <Footer></Footer>
        </div>
    )
    
}

export default Journey;

       
import React, { useEffect } from "react";
import $ from "jquery";
import Nav from "./Navbar";
import Footer from "./Footer";
import icon from "../journey/Group 203.svg";
import { googleAnalyticsActions } from "../utils/google-analytics-init";

let year = false;
var basicPriceYear = "₹59988";
var premiumPriceYear = "₹119988";

$(document).ready(function () {
  $(".form-toggler span").on("click", function () {
    $(this).addClass("active");
    $(this).siblings("span").removeClass("active");
    
    if ($(this).siblings("span").text() == "Monthly") {
    $(this).parent().parent().parent().parent().children("div.rowMain").children("#basicyearlyprice").children().children(":first").children(":nth-child(2)").children("#yearlyPriceValue").text(basicPriceYear);
    
     $(this).parent().parent().parent().parent().children("div.rowMain").children("#premiumyearlyprice").children().children(":first").children(":nth-child(2)").children("#premiumyearlyPriceValue").text(premiumPriceYear);
     
     $(this).parent().parent().parent().parent().children("div.rowMain").children("#basicyearlyprice").children().children(":first").children(":nth-child(2)").children("#perYearBasicValue").text('Per year');

     $(this).parent().parent().parent().parent().children("div.rowMain").children("#premiumyearlyprice").children().children(":first").children(":nth-child(2)").children("#perYearPremiumValue").text('Per year');
     
     
    }
    else{
      $(this).parent().parent().parent().parent().children("div.rowMain").children("#basicyearlyprice").children().children(":first").children(":nth-child(2)").children("#yearlyPriceValue").text("₹4999");

         $(this).parent().parent().parent().parent().children("div.rowMain").children("#premiumyearlyprice").children().children(":first").children(":nth-child(2)").children("#premiumyearlyPriceValue").text("₹9999");

         $(this).parent().parent().parent().parent().children("div.rowMain").children("#basicyearlyprice").children().children(":first").children(":nth-child(2)").children("#perYearBasicValue").text('Per month');

         $(this).parent().parent().parent().parent().children("div.rowMain").children("#premiumyearlyprice").children().children(":first").children(":nth-child(2)").children("#perYearPremiumValue").text('Per month');

    }
  });
});

const Landing = () => {
  useEffect(()=>{
    googleAnalyticsActions.initGoogleAnalytics();
},[]);

  return (
    <div>
      <Nav></Nav>
      <div
        style={{
          width: 300,
          margin: "auto",
          display: "block",
          lineHeight: 1.7,
          paddingBottom: 30,
        }}
      >
        <br />
        <br />
        <br />
        <h1 style={{ fontWeight: 900, textAlign: "center" }}>
          Simple pricing for everyone
        </h1>
        <span
          style={{ color: "#73899D", textAlign: "center", display: "block" }}
        >
          Pricing built for buisness of all sizes Always know what you’ll pay.
        </span>
        <br />
        <br />
        <div
          style={{
            backgroundColor: " #F3F9FF",
            borderRadius: 50,
            position: "relative",
            display: "block",
            margin: "auto",
            width: 250,
            padding: 4,
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "-20px",
              right: 0,
              fontSize: 13,
              fontWeight: 700,
              color: "#007bfe",
            }}
          >
            20% Discount
          </span>
          <b className="form-toggler" style={{ display: "flex" }}>
            <span className="active">Monthly</span>
            <span>Yearly</span>
          </b>
        </div>
      </div>



      <div
      className="rowMain"
        style={{display:"grid", margin: "auto", justifyContent: "center",alignContent:"center"}}>
        <div className="child-container1 price-container h">
          
            <div>
              <b style={{ fontSize: "120%" }}>Startup</b>
              <div
                style={{ display: "flex", marginTop: 20, alignItems: "center" }}
              >
                <h1 style={{ fontWeight: 900, fontSize: "3rem" }}>Free</h1>{" "}
                <span style={{ fontSize: 12, marginLeft: 5 }}></span>
              </div>
            </div>
            <ul className="price-info" style={{height: "64%"}}>
              <br />

              {/* <span style={{fontSize:12,color:'##73899D',display:'block',lineHeight:'1.2'}}>Unrestricted access to Mobile vault for end customers</span> */}
              <br />
              <li>
                <img class="iconer" src={icon} /> Can create upto 1000 QR code
              </li>
              <li>
                <img class="iconer" src={icon} /> Can create upto 2 promotional
                campaigns
              </li>
              <li>
                <img class="iconer" src={icon} /> Access to dashboard analytics*
              </li>
              <li>
                <img class="iconer" src={icon} /> Feedback/Review management
              </li>
              <li>
                <img class="iconer" src={icon} /> Access to 2 users
              </li>
              <li>
                <img class="iconer" />
              </li>
              <li>
                <img class="iconer" />
              </li>
            </ul>
            <br />
          <br/><a
              href="https://brand.stegofy.com/"
              className="light-face"
              style={{ backgroundColor: "white", padding: "17px 65px" }}
            >
              Get Started
            </a>
       
        </div>

        <div className="child-container2"  id="basicyearlyprice">
          <div className="price-container h">
            <div>
              <b style={{ fontSize: "120%" }}>Basic</b>
              <div
                style={{ display: "flex", marginTop: 20, alignItems: "center" }}
              >
                <h1
                  id="yearlyPriceValue"
                  style={{ fontWeight: 900, fontSize: "45px" }}
                >
                  ₹4999
                </h1>{" "}
                <span id="perYearBasicValue" style={{ fontSize: 12, marginLeft: 5 }}>Per month</span>
              </div>
            </div>
            <ul className="price-info">
              <br />

              {/* <span style={{fontSize:12,color:'##73899D',display:'block',lineHeight:'1.2'}}>Unrestricted access to Mobile vault for end customers</span> */}
              <br />
              <li>
                <img class="iconer" src={icon} /> Promotional campaigns
                <br /> Upto 5 per month
              </li>
              <li>
                <img class="iconer" src={icon} /> Feedback/Reviews Management
              </li>
              <li>
                <img class="iconer" src={icon} /> Daily Analytics Report
              </li>
              <li>
                <img class="iconer" src={icon} /> Add Unlimited Products
              </li>
              <li>
                <img class="iconer" src={icon} /> 1 Year QR Scan Validity
              </li>
              <li>
                <img class="iconer" src={icon} /> Generate QR Codes each at Rs.
                0.30p
              </li>
              <li>
                <img class="iconer" src={icon} /> Get Product Track & Trace
                Solution
              </li>
            </ul>
            <br />
            <a
             href="https://brand.stegofy.com/"
              className="light-face"
              style={{ backgroundColor: "white", padding: "17px 65px" }}
            >
              Get Started
            </a>
          </div>
        </div>

        <div className="child-container3"  id="premiumyearlyprice">
          <div className="price-container h">
            <div>
              <b style={{ fontSize: "120%" }}>Premium</b>
              <div
                style={{ display: "flex", marginTop: 20, alignItems: "center" }}
              >
                <h1 id="premiumyearlyPriceValue" style={{ fontWeight: 900, fontSize: "45px" }}>₹9999</h1>{" "}
                <span id="perYearPremiumValue" style={{ fontSize: 12, marginLeft: 5 }}>Per month</span>
              </div>
            </div>
            <ul className="price-info">
              <br />

              {/* <span style={{fontSize:12,color:'##73899D',display:'block',lineHeight:'1.2'}}>Unrestricted access to Mobile vault for end customers</span> */}
              <br />
              <li>
                <img class="iconer" src={icon} /> All Basic Features +
              </li>
              <li>
                <img class="iconer" src={icon} /> Support Management & Warranty
                Management
              </li>
              <li>
                <img class="iconer" src={icon} /> Product AR Experience
              </li>
              <li>
                <img class="iconer" src={icon} /> Dedicated account manager
              </li>
              <li>
                <img class="iconer" src={icon} /> Loyalty Management (discount
                coupons/vouchers )
              </li>
              <li>
                <img class="iconer" src={icon} /> Early Access To All New
                Features
              </li>
              <li>
                <img class="iconer" src={icon} /> Premium Analytics for deep
                insights
              </li>
            </ul>
            <br />
            <a
              href="https://brand.stegofy.com/"
              className="light-face"
              style={{ backgroundColor: "white", padding: "17px 65px" }}
            >
              Get Started
            </a>
          </div>
        </div>

        <div  className="child-container4 price-container h" >
          
            <div>
              <b style={{ fontSize: "120%" }}>Enterprise</b>
              <div
                style={{ display: "flex", marginTop: 20, alignItems: "center" }}
              >
                <h1 style={{ fontWeight: 900, fontSize: "3rem" }}>₹ </h1>{" "}
                <span style={{ fontSize: 12, marginLeft: 5 }}>
                  Custom pricing
                </span>
              </div>
            </div>
            <ul className="price-info"style={{height: "68%"}}>
              <br />

              {/* <span style={{fontSize:12,color:'##73899D',display:'block',lineHeight:'1.2'}}>Unrestricted access to Mobile vault for end customers</span> */}
              <br />
              <li>
                <img class="iconer" />
              </li>
              <li>
                <img class="iconer" />
              </li>
              <li>
                <img class="iconer" />
              </li>
              <h2>Contact Us</h2>
              <li>
                <img class="iconer" />
              </li>
              <li>
                <img class="iconer" />
              </li>
              <li>
                <img class="iconer" />
              </li>
              <li>
                <img class="iconer" />
              </li>
              <li>
                <img class="iconer" />
              </li>
            </ul>
            <br />
            <a
              href="/Contact"
              className="light-face"
              style={{ backgroundColor: "white", padding: "17px 65px" }}
            >
              Get Started
            </a>
          </div>
     
      </div>
      <br />
      <Footer></Footer>
    </div>
  );
};

export default Landing;

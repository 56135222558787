import React from "react";
import $ from "jquery";
import Nav from './Navbar';
import Footer from './Footer';
import { googleAnalyticsActions } from "../utils/google-analytics-init";
import { useEffect } from "react";

$(document).on('click','.shark > div > div', function(){
    $(this).children('i').toggle();
    $(this).next().slideToggle();
});
const Faq = ()=> {
    useEffect(()=>{
        googleAnalyticsActions.initGoogleAnalytics();
    },[]);
    return(
        <div>
            <Nav></Nav>
            <div className="faq row container">
            <div style={{width:'100%'}}>
            <br/>
                <br/>
                <br/>
                <h1 style={{fontWeight:900,textAlign:'center'}}>Frequently asked questions</h1>
                <br/>
                <h3>Consumer FAQ's</h3>
                <br/>
            </div>
            <div className="shark" style={{width:'100%'}}>
                <div>
                    <div className="first">
                        <b>What is Stegofy?</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    
                    <span>Stegofy is a platform to authenticate products before purchase & get complete information about the product with a single scan.</span>
                </div>
                <div>
                    <div>
                        <b>Why did you build StegofyApp?</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Counterfeiting is one of the biggest problems facing the entire world. And this is the problem in almost every product segment like FMCG, Food & Beverages, Pharma, Automobiles, Electronics, Computer Hardware & Software, Lifestyle & Apparel, and Hardware & Tools, etc. No segment is Untouched. Because of these counterfeit products, so many people's health is being affected, and in some cases, people's or losing their lives. Due to these counterfeit products, many popular brand names are losing their brand image & trust in the consumers, and that's gradually leading to huge revenue losses for the company. So we put our brains to work and built Stegofy to help everyone to buy authentic products. </span>
                </div>
                <div>
                    <div>
                    <b> Do I need to pay for it?</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    
                    <span>StegofyApp is, and always will be, completely free! We're helping make the world a better & safe place.</span>
                </div>
                <div>
                    <div>
                    <b>How do I get benefit from this?</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    
                    <span>Whenever you purchase the Stegofy authenticated products you will get rewards and that can be redeemed for next purchase.</span>
                </div>
                <div>
                    <div>
                    <b>How do I send If I have questions/feedback or suggestions ?</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>We would love to hear from you. If you need to reach out to us just send an email to support@stegofy.com</span>
                </div>
                 <br/>
                <h3>Manufacturer FAQ's</h3>
                <br/>
                <div>
                    <div>
                    <b>What I can do with Stegofy?</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>With Stegofy, you can: Track the entire journey of the product from manufacturing until reaching consumers. Eliminate counterfeit products. Manage warranty & support. Earn access to your loyal customers. And much more! </span>
                </div>
                <div>
                    <div>
                    <b>I want to allow my customers to buy products safely</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Stegofy allows anyone to ensure the authenticity of your product, in seconds, by simply scanning the QR code on the product with their smartphone. The process does not alter the product in any way, so it can be done freely, in the store, before purchase.</span>
                </div>
                <div>
                    <div>
                    <b>I want to facilitate customs clearance of my products </b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Stegofy makes it possible to ensure the authenticity of your product, in a few seconds, by simply scanning QR code with a smartphone. When a container of your products is held in customs for verification, it can be validated simply and quickly. </span>
                </div>
                <div>
                    <div>
                    <b>I want to geolocate my products to know my request</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Stegofy allows products to be located in real-time when they are scanned. You will know where your customers are and where your products are sold all over the world.</span>
                </div>
                <div>
                    <div>
                    <b>I want to make my inspectors’ job easier</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>This unique solution for its speed, simplicity and reliability will allow you to multiply their efficiency. </span>
                </div>
                <div>
                    <div>
                    <b>I want to geolocate my products to identify counterfeits</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Stegofy allows products and their counterfeits to be located in real-time when they are scanned. You’ll know where counterfeit products are circulating around the world.</span>
                </div>
                <div>
                    <div>
                    <b>I want to have a better understanding of my distribution channel</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Stegofy is compatible with any system of traceability and internet of objects to offer you a certified follow-up of each product.</span>
                </div>
                <div>
                    <div>
                    <b>I want to know my consumers better</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Stegofy lets you know which single user is doing the authentication of which of your products. You will be able to differentiate frequent, occasional consumers, and those discovering your products as well as resellers at different levels. Users of the Stegofy service can agree to transmit neutral information about themselves, for example, age or gender, allowing you to draw a portrait of your typical customer. </span>
                </div>
                <div>
                    <div>
                    <b>I want to retain my consumers</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Stegofy allows you to push the information of your choice to the consumer. Such as product information, links to your website, or accounts on social networks, advertisements for other products, or special promotions.</span>
                </div>
                <div>
                    <div>
                        <b>I want to give precise information about the quality of my products</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Each product is unique and identified as such: the date and precise location of the production and the product with its characteristics. Complementary information can also be given: like recipe suggestions, recycling instructions, etc., </span>
                </div>
                <div>
                    <div>
                        <b> I have more questions</b>
                        <i className="icon-plus"></i>
                        <i className="icon-minus"></i>
                    </div>
                    <span>Feel free to contact us if you have any questions about our technology. Also, don’t hesitate to drop us a message at enquiry@stegofy.com for samples, additional documentation, or to organize a call. We will happily get back to you.</span>
                </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
    
}

export default Faq;

       
import React from "react";
import Nav from './Navbar';
import Footer from './Footer';
import logo from '../svg/logo.svg';
import image1 from "../journey/Rectangle 240.svg";
import icon from '../journey/Group 203.svg';
import { googleAnalyticsActions } from "../utils/google-analytics-init";
import { useEffect } from "react";
const VerifyN = ()=> {
    useEffect(()=>{
        googleAnalyticsActions.initGoogleAnalytics();
    },[]);
    return(
        <div>
            <nav className="nav-bar">
      <a href="/" className="nav-link"> <img src={logo} /></a>
        <li> <a href="/" style={{fontSize:19.5,fontWeight: 800,color:'#007bfe'}}> Back to Stegofy </a> </li>
      
      </nav>
            <div className="row container journey" style={{margin:'auto',backgroundColor:'white'}}>
                <div className="col-md-7">
                    <img src={image1} style={{width:'100%',display:'flex',marginBottom:30}}/>
                </div>
                <div className="col-md-5" style={{display:'grid',alignContent: 'center',borderRadius:20,backgroundColor:'#F3F9FF',minHeight:250,marginBottom:30}}>
                    <b style={{margin:'auto',display:'block',marginBottom:10,marginTop:10}}> <img class="iconer" src={icon}/> Product Verified</b>
                    <span style={{textAlign:'center',color:'#73899D',fontSize:14,margin:'auto',display:'block'}}>If you have any concerns or queries in relation to product you have in your possesion please contact us by choosing report button
                    </span> 
                    <br/>
                    <table className="verify-table">
                        <tr>
                            <td> Product</td>
                            <td> Cocooil Body Oil Organic Coconut Oil</td>
                        </tr>
                        <tr>
                            <td> Batch</td>
                            <td> 32324h</td>
                        </tr>
                        <tr>
                            <td> State:</td>
                            <td> Mumbai , Maharashtra</td>
                        </tr>
                        <tr>
                            <td> Origin:</td>
                            <td> India</td>
                        </tr>
                        <tr>
                            <td> Expiray:</td>
                            <td> 12/22</td>
                        </tr>
                    </table>
                    <div className="verify-links">
                        <div>
                            <a href="#" className="light-face">Trace</a>
                            <a href="#" className="light-face">Claim</a>
                        </div>
                        <br/>
                        <div>
                            <a href="#" className="blue-face">View Report</a>
                            <a href="#" className="light-face" style={{border:'1.59159px solid #F55F44',backgroundColor:'white'}}>Report</a>
                        </div>
                    </div>
                </div>

            </div>
            <Footer></Footer>
        </div>
    )
    
}

export default VerifyN;

       
import React, { useState,useEffect } from "react";
import logo from '../svg/Group 31(1).svg';
import validator from 'validator'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from 'axios';

const Footer = ()=> {
  const [email, setemail] = useState('')
  const [emailError, setemailError] = useState(false)
  const [statuspop, setstatuspop] = useState({
    open: false,
    text: "",
    color: "",
  });
 

  const emailHandler=(e)=>{
    const temp=e.target.value;
     setemail(temp); 
    //  if (validator.isEmail(email)) {

    //   setemailError(false);
    // } 
  }


  const emailSubmit=(e)=>{
    e.preventDefault();
    if (validator.isEmail(email)) {

      setemailError(false);

      setstatuspop({
        color: "success",
        open: true,
        text: "Email submitted successfully",
      });
      // console.log(emailError);
    } 
    
    else {
     
      setemailError(true);
      setstatuspop({
        color: "error",
        open: true,
        text: "Email submission failed",
      });
      // console.log(emailError);
    }

    axios.post('https://melioristic-allowan.000webhostapp.com//contact/subscribe.php', {
      subscribe: email,
      
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
   
    }

   
    return(
        <footer className="no-margin dark-back" style={{paddingLeft:10,paddingRight:10,width:'100%'}}>
          
        <div className="row no-margin" style={{padding:10}} >
        {statuspop && (
            <div>
              <Snackbar
                open={statuspop.open}
                TransitionComponent="SlideTransition"
                autoHideDuration={6000}
                onClose={() => {
                  setstatuspop({
                    ...statuspop,
                    open: false,
                  });
                }}
              >
                <Alert
                  onClose={() => {
                    setstatuspop({
                      ...statuspop,
                      open: false,
                    });
                  }}
                  severity={statuspop.color}
                  sx={{ width: "100%" }}
                >
                  {statuspop.text}
                </Alert>
              </Snackbar>
            </div>
          )}
          <div className="col-sm-6 col-md-3">
            <div className="footer-body">
              <img src={logo} />
              <span> Stegofy protects Authenticity of brands & helps customers buy genuine products smartly with a great customer experience</span>
              <div>
               <a href="https://www.instagram.com/"> <i className="icon-instagram" ></i> </a>
               <a href="https://www.twitter.com/"> <i className="icon-twitter" ></i></a>
               <a href="https://www.linkedin.com/company/stegofy"> <i className="icon-linkedin" ></i></a>
               <a href="https://www.facebook.com/">  <i className="icon-facebook" ></i></a>
              </div> 
            </div>
        </div>
          <div className="col-sm-6 col-md-2">
            <ul>
              <li> <b> Company  </b> </li>
              <li> <a href="#"> Blog</a> </li>
              <li> <a href="#"> Careers</a> </li>
              <li> <a href="#"> About Us</a> </li>
            </ul>
        </div>
          <div className="col-sm-6 col-md-2">
            <ul>
              <li> <b> Product </b> </li>
              <li> <a href="#"> Watch Demo</a> </li>
              <li> <a href="#"> Term of Service</a> </li>
              <li> <a href="#"> Privacy Policy</a> </li>
            </ul>
        </div>
          <div className="col-sm-6 col-md-2">
            <ul>
              <li> <b> Help & Support </b> </li>
              <li> <a href="#"> Report Bug</a> </li>
              <li> <a href="#"> Share Feedback</a> </li>
              <li> <a href="#"> Contact Support</a> </li>
            </ul>
        </div>
          <div className="col-sm-6 col-md-3">
            <ul>
              <li> <a > Subscribe to our Newsletter </a> </li>
              <li> 
                <form style={{position: 'relative'}}>
                  <i className="icon-envelope"></i>
                  <input type="input" 
                  placeholder="Enter Email"
                  value={email}
                  style={{border: emailError&&Boolean(email) ? "1px solid red" : "1px solid"}}
                  onChange={emailHandler}
                   />
                  <button style={{outline: "none"}} onClick={emailSubmit}>Get Started</button> 
                </form>
                </li>
            </ul>
        </div>
          
      </div>
      <br/><hr style={{borderTop: '1px solid #435976'}}/>
      <span style={{color:'#435976',textAlign:'center',fontSize:12,lineHeight:1.1,display:'block',margin:9}}>Copyright © 2021. Stegofy All rights reserved | Privacy policy | Refund and Cancellation Policy | Terms of Use | Cookies | Sitemap</span>
      </footer>
    )
    
}

export default Footer;
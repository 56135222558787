import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Landing  from './components/Landing';
import Pricing from './components/Pricing';
import Faq from './components/Faq';
import Journey from './components/Journey';
import Contact from './components/Contact';
import Verify from './components/Verify';
import VerifyN from './components/Verify-next';
import './fonts/icomoon/style.css'

function App() {  
  return (
    <Router>
        <Route path="/" exact component={Landing} />
        <Route path="/Pricing"  component={Pricing} />
        <Route path="/Faq"  component={Faq} />
        <Route path="/OurJourney"  component={Journey} />
        <Route path="/Contact"  component={Contact} />
        <Route path="/Verify"  component={Verify} />
        <Route path="/Verify-next"  component={VerifyN} />
    </Router>
    
  );
}

export default App;

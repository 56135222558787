import React, { useState, useEffect } from "react";
import $ from "jquery";
import logo from "../svg/logo.svg";
import barcode from "../svg/barcode.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "../css/navbar.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const showDrop = () => {
  $(".dropdown-menu").slideToggle("slow");
};

const Nav = () => {
  const [toggle, settoggle] = useState(false);
  const [iconMenuToggle, seticonMenuToggle] = useState(false);
  const [scroll, setscroll] = useState(false);

  const ourProductsList = [
    {
      id: 1,
      name: "Augmented Reality",
      path: "https://ar.stegofy.com/",
      isComingSoon: false,
    },
    {
      id: 2,
      name: "Consumer Intelligence",
      path: "#",
      isComingSoon: true,
    },
    {
      id: 3,
      name: "Matchsense",
      path: "#",
      isComingSoon: true,
    },
  ];

  const menuData = (e) => {
    e.preventDefault();
    $(".nav-list-menu").slideToggle("slow");

    if (toggle == false) {
      settoggle(true);
    } else {
      settoggle(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", function () {
      setscroll(true);
      $(".nav-list-menu").slideUp("slow");
      if ($(this).scrollTop() <= 0) {
        settoggle(false);
        setscroll(false);
      }
      // setscroll(false);
      // settoggle(false);
    });
  });

  return (
    <nav className="nav-bar">
      <a href="/" className="nav-link">
        {" "}
        <img src={logo} />
      </a>
      <ul className="nav-list">
        <li>
          {" "}
          <a href="/"> Home </a>{" "}
        </li>
        <li>
          <DropdownButton
            id="dropdown-basic-button"
            title="Our Products"
            variant="none"
          >
            {ourProductsList.map((eachProduct) => {
              return (
                <Dropdown.Item href={eachProduct.path} target="_blank">
                  {eachProduct.name}
                  {eachProduct.isComingSoon && (
                    <div className="coming-soon-in-nav">Coming Soon</div>
                  )}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </li>
        <li>
          {" "}
          <a href="/Pricing" className="pricing">
            {" "}
            Pricing{" "}
          </a>{" "}
        </li>
        <li>
          {" "}
          <a href="/Faq"> FAQs </a>{" "}
        </li>
        <li>
          {" "}
          <a href="/Contact"> Contact Us </a>{" "}
        </li>
        <li>
          <a href="/Verify" className="blue-face">
            {" "}
            <img style={{ marginRight: 11 }} src={barcode} /> Verify{" "}
          </a>{" "}
        </li>
        <li>
          <a href="https://brand.stegofy.com/" className="light-face">
            {" "}
            Login{" "}
          </a>{" "}
        </li>
      </ul>
      <ul className="nav-list-menu">
        <li>
          {" "}
          <a href="/"> Home </a>{" "}
        </li>
        <li>
          <DropdownButton
            id="dropdown-basic-button"
            title="Our Products"
            variant="none"
          >
            {ourProductsList.map((eachProduct) => {
              return (
                <Dropdown.Item href={eachProduct.path} target="_blank">
                  {eachProduct.name}
                  {eachProduct.isComingSoon && (
                    <div className="coming-soon-in-nav">Coming Soon</div>
                  )}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </li>
        <li>
          {" "}
          <a href="/Pricing" className="pricing">
            {" "}
            Pricing{" "}
          </a>{" "}
        </li>
        <li>
          {" "}
          <a href="/Faq"> FAQs </a>{" "}
        </li>
        <li>
          {" "}
          <a href="/Contact"> Contact Us </a>{" "}
        </li>
        <li>
          <a href="/Verify" className="blue-face">
            {" "}
            <img style={{ marginRight: 11 }} src={barcode} /> Verify{" "}
          </a>{" "}
        </li>
        <li>
          <a href="https://brand.stegofy.com/" className="light-face">
            {" "}
            Login{" "}
          </a>{" "}
        </li>
      </ul>
      {toggle ? (
        <i className="close-menu" onClick={menuData}>
          <CloseIcon sx={{ color: "#007bfe", cursor: "pointer" }} />
        </i>
      ) : (
        <i className="icon-menu" onClick={menuData}></i>
      )}
    </nav>
  );
};

export default Nav;

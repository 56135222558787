import React from "react";
import headerpics from "../svg/undraw_Alert_re_j2op 1.svg";
import background from "../svg/Header-Background.svg";
const OurProducts = () => {
  return (
    <header className="our-products">
      <div className="col">
        <h1 className="heading">
          Our <span className="theme-color">Products</span>
        </h1>
        <br />
        <br />
        <div className="our-products-btns-container">
          <div className="each-btn">
            <a
              href="https://ar.stegofy.com/"
              target="_blank"
              className="blue-face"
            >
              Augmented Reality
            </a>
          </div>
          <div className="each-btn">
            <button className="blue-face">Consumer Intelligence</button>
            <div className="soon">Coming Soon</div>
          </div>
          <div className="each-btn">
            <button className="blue-face">Matchsense</button>
            <div className="soon">Coming Soon</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default OurProducts;

import React from "react";
import Nav from "./Navbar";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import OurProducts from "./OurProducts";
import $ from "jquery";
import { useEffect } from "react";
import { googleAnalyticsActions } from "../utils/google-analytics-init";

const Landing = () => {
  useEffect(() => {
    googleAnalyticsActions.initGoogleAnalytics();
  }, []);
  return (
    <div>
      <Nav></Nav>
      <Header></Header>
      <br />
      <OurProducts></OurProducts>
      <br />
      <Main></Main>
      <Footer></Footer>
    </div>
  );
};

export default Landing;

import React, { useEffect,useState ,useRef} from "react";
import $ from "jquery";
import mobilepost from "../svg/mobile_post.svg";
import plus from "../svg/file-plus-alt 1.svg";
import star from "../svg/star 1.svg";
import location from "../svg/location-pin-alt 1.svg";
import card4 from "../svg/Group 86.svg";
import card1 from "../svg/undraw_Security_on_re_e491 (1) 1.svg";
import card2 from "../svg/undraw_Mobile_marketing_re_p77p 1.svg";

import card3 from '../svg/undraw_Wireframing_re_q6k6 1.svg';
import card7 from "../svg/claim product 1024.svg";
import card5 from "../svg/Productinfo 1024.svg";
import card6 from "../svg/printnapply 1024.svg";
import cost from "../svg/cost.svg";
import { SliderData } from './SliderData';
import card8 from "../svg/scanqr 1024.svg";
import card9 from "../svg/register 1024.svg";
import hander from "../svg/Group 171.svg";
import icon1 from "../svg/Vector(1).svg";
import icon2 from "../svg/Vector(2).svg";
import phones from "../svg/phones.jpg";
import phone1 from "../journey/iPhone 8 - 4.png";
import phone2 from "../journey/iPhone 8 - 2.png";
import phone3 from "../journey/iPhone 8 - 1.png";
import phone4 from "../journey/iPhone 8 - 5.png";
import phone5 from "../journey/iPhone 8 - 6.png";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import '../css/carousel.css';

// import Carousel from "react-material-ui-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import "slick-carousel/slick/slick.css";
import ItemsCarousel from 'react-items-carousel';




const Main = () => {

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const arrayImage = [phone1, phone2, phone3, phone4,phone5];
  const [current, setCurrent] = useState(0);
  const [arrow, setarrow] = useState('next')
  
  const length = SliderData .length;
  const autoPlayRef = useRef()


  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
   };




   useEffect(() => {
     
    const cardsContainer = document.querySelector(".card-carousel");
    const cardsController = document.querySelector(".card-carousel + .card-controller")

class DraggingEvent {
  constructor(target = undefined) {
    this.target = target;
  }
  
  event(callback) {
    let handler;
    
    this.target.addEventListener("mousedown", e => {
      e.preventDefault()
      
      handler = callback(e)
      
      window.addEventListener("mousemove", handler)
      
      document.addEventListener("mouseleave", clearDraggingEvent)
      
      window.addEventListener("mouseup", clearDraggingEvent)
      
      function clearDraggingEvent() {
        window.removeEventListener("mousemove", handler)
        window.removeEventListener("mouseup", clearDraggingEvent)
      
        document.removeEventListener("mouseleave", clearDraggingEvent)
        
        handler(null)
      }
    })


    
    this.target.addEventListener("touchstart", e => {
      handler = callback(e)
      
      window.addEventListener("touchmove", handler)
      
      window.addEventListener("touchend", clearDraggingEvent)
      
      document.body.addEventListener("mouseleave", clearDraggingEvent)
      
      function clearDraggingEvent() {
        window.removeEventListener("touchmove", handler)
        window.removeEventListener("touchend", clearDraggingEvent)
        
        handler(null)
      }
    })
  }



  
  // Get the distance that the user has dragged
  getDistance(callback) {
    function distanceInit(e1) {
      let startingX, startingY;
      
      if ("touches" in e1) {
        startingX = e1.touches[0].clientX
        startingY = e1.touches[0].clientY
      } else {
        startingX = e1.clientX
        startingY = e1.clientY
      }
      

      return function(e2) {
        if (e2 === null) {
          return callback(null)
        } else {
          
          if ("touches" in e2) {
            return callback({
              x: e2.touches[0].clientX - startingX,
              y: e2.touches[0].clientY - startingY
            })
          } else {
            return callback({
              x: e2.clientX - startingX,
              y: e2.clientY - startingY
            })
          }
        }
      }
    }
    
    this.event(distanceInit)
  }
}






class CardCarousel extends DraggingEvent {
  constructor(container, controller = undefined) {
    super(container)
    
    // DOM elements
    this.container = container
    this.controllerElement = controller
    this.cards = container.querySelectorAll(".card")
    
    // Carousel data
    this.centerIndex = (this.cards.length - 1) / 2;
    this.cardWidth = this.cards[0].offsetWidth / this.container.offsetWidth * 100
    this.xScale = {};
    


    // Resizing
    window.addEventListener("resize", this.updateCardWidth.bind(this))
    
    if (this.controllerElement) {
      this.controllerElement.addEventListener("keydown", this.controller.bind(this))      
    }

    
    // Initializers
    this.build()
    

    // Bind dragging event
    super.getDistance(this.moveCards.bind(this))
  }
  

  updateCardWidth() {
    this.cardWidth = this.cards[0].offsetWidth / this.container.offsetWidth * 100
    
    this.build()
  }
  
  build(fix = 0) {
    for (let i = 0; i < this.cards.length; i++) {
      const x = i - this.centerIndex;
      const scale = this.calcScale(x)
      const scale2 = this.calcScale2(x)
      const zIndex = -(Math.abs(i - this.centerIndex))
      
      const leftPos = this.calcPos(x, scale2)
     
      
      this.xScale[x] = this.cards[i]
      
      this.updateCards(this.cards[i], {
        x: x,
        scale: scale,
        leftPos: leftPos,
        zIndex: zIndex
      })
    }
  }
  
  
  controller(e) {
    const temp = {...this.xScale};
      
      if (e.keyCode === 39) {
        // Left arrow
        for (let x in this.xScale) {
          const newX = (parseInt(x) - 1 < -this.centerIndex) ? this.centerIndex : parseInt(x) - 1;

          temp[newX] = this.xScale[x]
        }
      }
      
      if (e.keyCode == 37) {
        // Right arrow
        for (let x in this.xScale) {
          const newX = (parseInt(x) + 1 > this.centerIndex) ? -this.centerIndex : parseInt(x) + 1;

          temp[newX] = this.xScale[x]
        }
      }
      
      this.xScale = temp;
      
      for (let x in temp) {
        const scale = this.calcScale(x),
              scale2 = this.calcScale2(x),
              leftPos = this.calcPos(x, scale2),
              zIndex = -Math.abs(x)

        this.updateCards(this.xScale[x], {
          x: x,
          scale: scale,
          leftPos: leftPos,
          zIndex: zIndex
        })
      }
  }
  
  calcPos(x, scale) {
    let formula;
    
    if (x < 0) {
      formula = (scale * 100 - this.cardWidth) / 2
      
      return formula

    } else if (x > 0) {
      formula = 100 - (scale * 100 + this.cardWidth) / 2
      
      return formula
    } else {
      formula = 100 - (scale * 100 + this.cardWidth) / 2
      
      return formula
    }
  }
  


  updateCards(card, data) {
    if (data.x || data.x == 0) {
      card.setAttribute("data-x", data.x)
    }

  
   

    if (data.scale || data.scale == 0) {   
      card.style.transform = `scale(${data.scale})`

      if(Math.abs(data.scale)<0.3){
        card.style.transform = `scale(0.6)`
      }

      if (data.scale == 0) {
        card.style.opacity = data.scale
      } else {
        card.style.opacity = 1;
      }
    }
   
    if (data.leftPos) {
      card.style.left = `${data.leftPos}%`        
    }
    
    if (data.zIndex || data.zIndex == 0) {
      if (data.zIndex == 0) {
        card.classList.add("highlight")
      } else {
        card.classList.remove("highlight")
      }
      
      card.style.zIndex = data.zIndex  
    }
  }



  
  calcScale2(x) {
    let formula;
   
    if (x <= 0) {
      formula = 1 - -1 / 5 * x
      
      return formula
    } else if (x > 0) {
      formula = 1 - 1 / 5 * x
      
      return formula
    }
  }
  



  calcScale(x) {
    const formula = 1 - 1 / 5 * Math.pow(x, 2)
    
    if (formula <= 0) {
      return 0 
    } else {
      return formula      
    }
  }
  




  checkOrdering(card, x, xDist) {    
    const original = parseInt(card.dataset.x)
    const rounded = Math.round(xDist)
    let newX = x
    
    if (x !== x + rounded) {
      if (x + rounded > original) {
        if (x + rounded > this.centerIndex) {
          
          newX = ((x + rounded - 1) - this.centerIndex) - rounded + -this.centerIndex
        }
      } else if (x + rounded < original) {
        if (x + rounded < -this.centerIndex) {
          
          newX = ((x + rounded + 1) + this.centerIndex) - rounded + this.centerIndex
        }
      }
      
      this.xScale[newX + rounded] = card;
    }
    
    const temp = -Math.abs(newX + rounded)
    
    this.updateCards(card, {zIndex: temp})

    return newX;
  }




  
  moveCards(data) {
    let xDist;
    
    if (data != null) {
      this.container.classList.remove("smooth-return")
      xDist = data.x / 250;
    } else {

      
      this.container.classList.add("smooth-return")
      xDist = 0;




      for (let x in this.xScale) {
        this.updateCards(this.xScale[x], {
          x: x,
          zIndex: Math.abs(Math.abs(x) - this.centerIndex)
        })
      }
    }




    for (let i = 0; i < this.cards.length; i++) {
      const x = this.checkOrdering(this.cards[i], parseInt(this.cards[i].dataset.x), xDist),
            scale = this.calcScale(x + xDist),
            scale2 = this.calcScale2(x + xDist),
            leftPos = this.calcPos(x + xDist, scale2)
      
      
      this.updateCards(this.cards[i], {
        scale: scale,
        leftPos: leftPos
      })
    }
  }
}

const carousel = new CardCarousel(cardsContainer)
   }, [])


   useEffect(() => {
  autoPlayRef.current=nextSlide;
})

useEffect(() => {

const play =()=>{
 
  autoPlayRef.current();
}
const interval=setInterval(play,5000);
},[])

  
  if(current === 0){
    arrayImage[0]=phone1;
    setTimeout(1000);
    arrayImage[1]=phone2;
    setTimeout(1000);
    arrayImage[2]=phone3;
    setTimeout(1000);
    arrayImage[3]=phone4;
    setTimeout(1000);
    arrayImage[4]=phone5;
    setTimeout(1000);
    

  }
   if(current === 1){

  
    arrayImage[0]=phone5;
    setTimeout(1000);
    arrayImage[1]=phone1;
    setTimeout(1000);
    arrayImage[2]=phone2;
    setTimeout(1000);
    arrayImage[3]=phone3;
    setTimeout(1000);
    arrayImage[4]=phone4;
    setTimeout(1000);
  }
   if(current === 2){
    arrayImage[0]=phone4;
    setTimeout(1000);
    arrayImage[1]=phone5;
    setTimeout(1000);
    arrayImage[2]=phone1;
    setTimeout(1000);
    arrayImage[3]=phone2;
    setTimeout(1000);
    arrayImage[4]=phone3;
    setTimeout(1000);
  }
   if(current === 3){
    arrayImage[0]=phone3;
    setTimeout(1000);
    arrayImage[1]=phone4;
    setTimeout(1000);
    arrayImage[2]=phone5;
    setTimeout(1000);
    arrayImage[3]=phone1;
    setTimeout(1000);
    arrayImage[4]=phone2;
    setTimeout(1000);
  }
   if(current === 4){
    arrayImage[0]=phone2;
    setTimeout(1000);
    arrayImage[1]=phone3;
    setTimeout(1000);
    arrayImage[2]=phone4;
    setTimeout(1000);
    arrayImage[3]=phone5;
    setTimeout(1000);
    arrayImage[4]=phone1;
    setTimeout(1000);
  }
  

  return (
    <div id="ulId" >
      <div style={{marginInline:"14px"}}>
      <section className="row main">
        <div className="col-lg-4">
          <img src={mobilepost} style={{ width: "100%" }} />
        </div>
        <div className="col-lg-8 mmtop">
          <div>
            <h2
              style={{ fontWeight: "900", lineHeight: 1.3, textAlign: "left" }}
            >
              What are The Consumers’ Concerns During Shopping?
            </h2>
            <div
              className="row"
              style={{ minHeight: 220, alignContent: "space-evenly" }}
            >
              <div className="customer-box col-sm-5">
                <div style={{ display: "flex" }}>
                  <img src={plus} />
                  <div>
                    <b>What are the Ingredients?</b>
                    <span>
                      Does it contain ingredients that might trigger allergic
                      reactions?
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-1"></div>
              <div className="customer-box col-sm-5">
                <div style={{ display: "flex" }}>
                  <img src={star} />
                  <div>
                    <b>User Review/Rating?</b>
                    <span>Past buyers, how was their experiences?</span>
                  </div>
                </div>
              </div>
              <div className="customer-box col-sm-5">
                <div style={{ display: "flex" }}>
                  <img src={plus} />
                  <div>
                    <b>Is this Fake?</b>
                    <span>I’m not sure whether this is original or fake…</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-1"></div>
              <div className="customer-box col-sm-5">
                <div style={{ display: "flex" }}>
                  <img src={location} />
                  <div>
                    <b>Where is this from?</b>
                    <span>
                      I’ll be paranoid if I don’t know where it’s originated
                      from.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      </div>
      <section className="row main" style={{ backgroundColor: "white" }}>
        <h1
         className="scanToTrigger" style={{ textAlign: "center", fontWeight: "bolder", maxWidth: 800 }}
        >
          One Scan to Trigger Consumers{" "}
          <span className="theme-color">
            360<sup>o</sup>{" "}
          </span>{" "}
          Product Experiences
        </h1>
        <span
          style={{
            color: "#74899d",
            display: "block",
            maxWidth: 800,
            margin: "20px auto",
            textAlign: "center",
            marginInline:'18px'
          }}
        >
          With Stegofy solution, your product packaging and labelling no longer
          provides a static content and Making your consumers choose your
          product over a competitors.
        </span>
        <br />
        <div className="card-container">
          <div className="cards">
            <img src={card1} />
            <b>Anti-Counterfeit</b>
            <span>
              Every product unit contains a unique code fortified with
              Blockchain technology, reassuring customers the authenticity of
              your products.
            </span>
          </div>
          <div className="cards">
            <img src={card2} />
            <b>Marketing</b>
            <span>
              Now your product is able to become the entry point for various
              marketing activities
            </span>
          </div>
          <div className="cards">
            <img src={card3} />
            <b>Product Transparency</b>
            <span>
            Let customers access complete product data including its origin, ingredients, 
            nutrients etc to win their hearts.
            </span>
          </div>
          <div className="cards">
            <img src={card4} />
            <b>Rating & Testimony</b>
            <span>
              Your consumers are more likely to buy your product once they
              discover how many people love it.
            </span>
          </div>
        </div>
      </section>
      <section className="main" style={{ backgroundColor: "white" }}>
        <h2 style={{ textAlign: "center", fontWeight: "bolder" }}>
          How it works
        </h2>
        <br />
        <br />
        <div className="card-container">
          <div className="cards c2">
            <img src={card9} />
            <b>Register your Products</b>
            <span>
            Once signedup you can add product names & information
            </span>
          </div>
          <div className="cards c2">
            <img src={card6} />
            <b>Print & apply QR codes</b>
            <span>
            Print out the generated QR Codes & apply them on your products
            </span>
          </div>
          <div className="cards c2">
            <img src={card8} />
            <b>Consumers scan the QR code</b>
            <span>
            Shoppers can use their mobile device to scan QR code on the packaging
            </span>
          </div>
          <div className="cards c2">
            <img src={card5} />
            <b>Product info is displayed</b>
            <span>
              {" "}
              Explore all the information related to products, reviews, ingredients along with its usage instructions
            </span>
          </div>
          <div className="cards c2">
            <img src={card7} />
            <b>Consumers can claim product</b>
            <span>
            With this final step, a consumer get complete ownership on the product along with rewards, vouchers
            </span>
          </div>
        </div>
      </section>
     
      <section
        className="row main"
        style={{
          backgroundColor: "white",
          display: "grid",
          justifyContent: "center",
          minHeight: "auto",
        }}
      >
        <h2 style={{ textAlign: "center", fontWeight: "bolder" }}>
          Product Features
        </h2>
  

<div class="slider">
  <div class="card-carousel">
    <div class="card" id="1">
      <img src={arrayImage[0]} className='image'  />
    </div>
    <div class="card" id="2">
    <img src={arrayImage[1]} className='image'  />
    </div>
    <div class="card" id="3">
    <img src={arrayImage[2]} className='image'  />
    </div>  
    <div class="card" id="4">
    <img src={arrayImage[3]} className='image'  />
    </div>
    <div class="card" id="5" >
    <img src={arrayImage[4]} className='image'  />
    </div>
  </div>
 
</div>


<div className='parent-grey-circle' >
  <div className={current == 3 ?'grey-circle-active':'grey-circle' }></div>
  <div className={current == 4 ?'grey-circle-active':'grey-circle' }></div>
  <div className={current == 0 ?'grey-circle-active':'grey-circle' }></div>
  <div className={current == 1 ?'grey-circle-active':'grey-circle' }></div>
  <div className={current == 2 ?'grey-circle-active':'grey-circle' }></div>
</div>
    


        <span
          style={{
            color: "#74899d",
            display: "block",
            maxWidth: 550,
            margin: "auto",
            textAlign: "center",
            marginTop: "10px"
          }}
        >
          We have successfully identified the essential functions of track &
          trace softwares throughout developing for various corporate clients.{" "}
        </span>
      </section>

      <br />
      <br />
      <div className="whyChoose" style={{paddingInline:'30px'}}>
      <section className="main" style={{display: "flex",flexWrap:'wrap',marginBottom:'35px'}}>
        <div className="col-lg-4" style={{ paddingLeft: 0 }}>
          <img src={hander} style={{ width: "100%" }} />
        </div>
        <div className="col-lg-1"> </div>
        <div className="col-lg-7 mmtop mmtop2">
          <div>
            <div className="hh">
              <h2 style={{ fontWeight: "900" }}>
                Why choose <span className="theme-color">Stegofy</span>
              </h2>
              <span style={{ color: "#74899d", fontSize: 14, marginBottom:'16px' }}>
                Stegofy platform is the secure, reliable and convenient one stop
                solution for all your brand and product management needs
              </span>
              <div>
                <a className="blue-face" href="#">
                  Learn More{" "}
                </a>
              </div>
            </div>
            <div
              className="row"
              style={{ minHeight: 220, alignContent: "space-evenly" }}
            >
              <div className="customer-box pix col-sm-12">
                <div style={{ display: "flex" }}>
                  <img src={cost} />
                  <div>
                    <b>Low Cost</b>
                    <span>
                      Stegofy platform stores all uploaded business and product
                      information in a secure, tamper proof and fraud resistant
                      format
                    </span>
                  </div>
                </div>
              </div>
              <div className="customer-box pix col-sm-12">
                <div style={{ display: "flex" }}>
                  <img src={icon1} />
                  <div>
                    <b>Secure</b>
                    <span>
                      Stegofy platform stores all uploaded business and product
                      information in a secure, tamper proof and fraud resistant
                      format
                    </span>
                  </div>
                </div>
              </div>
              <div className="customer-box pix col-sm-12">
                <div style={{ display: "flex" }}>
                  <img src={icon2} />
                  <div>
                    <b>Customer Experiance</b>
                    <span>
                      Stegofy platform stores all uploaded business and product
                      information in a secure, tamper proof and fraud resistant
                      format
                    </span>
                  </div>
                </div>
              </div>
              <div className="customer-box pix col-sm-12">
                <div style={{ display: "flex" }}>
                  <img src={star} />
                  <div>
                    <b>Affordable</b>
                    <span>
                      Why spend 3-6 months and 5-6 digits budget to develop a
                      software from scratch while Stegofy can deliver the
                      positive ROI to your organisation instantly.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </div>
  );
};

export default Main;





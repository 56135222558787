import ReactGA from 'react-ga';

const TRACKING_ID = "UA-151362154-2";
const googleAnalyticsActions = {};

googleAnalyticsActions.initGoogleAnalytics = async () =>{
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
};

export { googleAnalyticsActions };  

import Nav from './Navbar';
import Footer from './Footer';
import logo from '../svg/logo.svg';
import image1 from "../journey/Group 297.png";
import React, { useState } from "react";
import { googleAnalyticsActions } from "../utils/google-analytics-init";
import { useEffect } from "react";
const Verify = ()=> {

    useEffect(()=>{
        googleAnalyticsActions.initGoogleAnalytics();
    },[]);
    return(
        <div style={{display: 'flex',flexDirection: 'column',height: '100%'}}>
            <nav className="nav-bar">
      <a href="/" className="nav-link"> <img src={logo} /></a>
        <li> <a href="/" style={{fontSize:19.5,fontWeight: 800,color:'#007bfe'}}> Back to Stegofy </a> </li>
      </nav>
            <div className="container journeyVerify" style={{margin:'auto',backgroundColor:'white',height:'100%'}}>
                <div className="col-md-6" style={{display:'flex'}}>
                    <img src={image1} style={{width:'100%',minHeight:'318px',display:'flex',marginBottom:30}}/>
                </div>
                <div className="col-md-6" style={{display:'flex',flexDirection:'column',alignContent: 'center',borderRadius:20,backgroundColor:'#F3F9FF',minHeight:'318px',marginBottom:30,justifyContent: 'center',textAlign: 'center'}}>
                    <h2 style={{color:'black'}}>Scan the QR </h2>
                    <span>Place the non-cloneable Qr code in the frame to verify the aythencity of product and claim it yours
                    </span>
                </div>

            </div>
           
        <Footer/>
        </div>
    )
    
}

export default Verify;

       